/* eslint-disable @typescript-eslint/return-await */
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { addDays, isBefore } from 'date-fns';
import { Store } from '@ngrx/store';
import { PermissionServiceV2 } from '../../auth/services/permission-v2.service';
import { AuthUser } from '../../auth/auth.models';
import { UserService } from '../../auth/services/user.service';
import { AuthService } from '../../auth/services/auth.service';
import { FeatureFlagService } from './types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../constants/feature-flag.constants';
import { OnboardingStatus } from '../../auth/services/onboarding/onboarding.service';
import { APP_NAMES, CHARGEBEE_SUBSCTIPION_STATUS } from '../constants';
import { ApplicationsHelperService } from './applications.helper.service';
import { UserPreferencesService } from './user-preferences.service';
import { UserOnboardingService } from './user-onboarding.service';
import { PermissionHelperServiceV2 } from './permission-helper-service-v2';
import { UserAuthenticationService } from '../../auth/services/user-authentication.service';
import { LogoutAction } from '../../reducers/logout/logout.action';

interface RouteToPermissionsDTO {
  route: string;
  permissions: string[];
}

@Injectable()
export class RoutePermissionsService {
  readonly RootUrlCharacters = new Set(['/', '']);

  user: AuthUser;

  // when we add a new route add the respected permission for the route
  // the permissions array will check for atleast one permission for the user
  routeToPermissionMapping: RouteToPermissionsDTO[] = [
    {
      route: '/dashboard',
      permissions: [
        'dashboard::',
        'dashboard:revenue:read',
        'dashboard:logs:read',
        'dashboard:net-income:read',
        'dashboard:net-income-v2:read',
        'dashboard:notifications:read',
        'dashboard:register-chart:read',
      ],
    },
    {
      route: '/inventory/products',
      permissions: ['inventory:product-list:read'],
    },
    {
      route: '/reports',
      permissions: ['reports:reports-list:read'],
    },
    {
      route: '/order-management/orders',
      permissions: ['order-management:order-list:read'],
    },
    {
      route: '/order-management/invoices',
      permissions: ['order-management:invoice-list:read'],
    },
    {
      route: '/order-management/customers',
      permissions: ['order-management:customer-list:read'],
    },
    {
      route: '/order-management/settings',
      permissions: ['order-management:order-settings:read'],
    },
    {
      route: '/invoices/purchase-orders',
      permissions: ['stock-control:purchase-order-detail:read'],
    },
    {
      route: '/invoices/purchase-orders/v2',
      permissions: ['stock-control:purchase-order-detail:read'],
    },
    {
      route: '/invoices/purchase-order',
      permissions: ['stock-control:purchase-order-detail:read'],
    },
    {
      route: '/invoices/return-stocks',
      permissions: ['stock-control:return-stock-list:read'],
    },
    {
      route: '/invoices/remove-stock',
      permissions: ['stock-control:remove-stock-list:read'],
    },
    {
      route: '/invoices/transfer-stock',
      permissions: ['stock-control:transfer-stock-list:read'],
    },
    {
      route: '/invoices/stock-count',
      permissions: ['stock-control:stock-count-list:read'],
    },
    {
      route: '/invoices/payments',
      permissions: ['stock-control:payment-list:read'],
    },
    {
      route: '/invoices/suppliers',
      permissions: ['stock-control:supplier-list:read'],
    },
    {
      route: '/applications',
      permissions: ['applications::', 'applications:available-app:read'],
    },
    {
      route: '/applications/my-apps',
      permissions: ['applications:app-list:read'],
    },
    {
      route: '/users-settings/overview',
      permissions: ['settings:company-overview:read'],
    },
    {
      route: '/users-settings/users',
      permissions: ['settings:user-list:read'],
    },
    {
      route: '/users-settings/users/new',
      permissions: [
        'settings:user-detail:create',
        'settings:user-detail:update',
      ],
    },
    {
      route: '/users-settings/locations',
      permissions: ['settings:location-list:read'],
    },
    {
      route: '/users-settings/taxes',
      permissions: ['settings:tax-list:read'],
    },
    {
      route: '/users-settings/configuration',
      permissions: ['settings:configuration-list:read'],
    },
    {
      route: '/users-settings/paymentMethods',
      permissions: ['settings:payment-method-list:read'],
    },
    // {
    //   route: '/users-settings/finance',
    //   permissions: [],
    // },
    {
      route: '/users-settings/my-subscription',
      permissions: ['settings:subscription:read'],
    },
    {
      route: '/users-settings/my-subscription/checkout',
      permissions: ['settings:subscription:update'],
    },
    {
      route: '/users-settings/my-subscription/active-plan',
      permissions: ['settings:subscription:read'],
    },
    {
      route: '/pos/registers',
      permissions: ['pos:register:open'],
    },
    {
      route: '/pos/cash-management',
      permissions: ['pos:cash-management:read'],
    },
    {
      route: '/pos/settings',
      permissions: ['pos:receipt-setting:read'],
    },
    {
      route: '/pos/settings',
      permissions: ['pos:sales-setting:read'],
    },
    {
      route: '/pos/layout',
      permissions: ['pos:layout-setting:read'],
    },
    {
      route: '/promotions',
      permissions: ['promotions:promotion-list:read'],
    },
    {
      route: '/accounting/summary',
      permissions: ['accounting:dashboard:read'],
    },
    {
      route: '/expenses',
      permissions: [
        'expenses:expense-list.search',
        'expenses:expense-list:read',
      ],
    },
    {
      route: '/expenses/new',
      permissions: ['expenses:expense-detail:add'],
    },
    {
      route: '/expenses/',
      permissions: ['expenses:expense-detail:edit'],
    },
    {
      route: '/accounting/journal-entries/add-balance',
      permissions: ['accounting:opening-balance:add'],
    },
    {
      route: '/accounting/journal-entries/new-entry',
      permissions: ['accounting:journal-entry:add'],
    },
    {
      route: '/accounting/journal-entries/duplicate',
      permissions: ['accounting:journal-entry:add'],
    },
    {
      route: '/accounting/journal-entries/edit',
      permissions: ['accounting:journal-entry:update'],
    },
    {
      route: '/accounting/journal-entries/detail',
      permissions: ['accounting:journal-entry:read'],
    },
    {
      route: '/accounting/chart-of-accounts/detail',
      permissions: ['accounting:account-details:read'],
    },
    {
      route: '/accounting/chart-of-accounts/listing',
      permissions: ['accounting:chart-of-account:read'],
    },
    {
      route: '',
      permissions: [],
    },
  ];

  readonly landingPageWithoutSubscriptionV2 =
    '/users-settings/my-subscription/active-plan';

  readonly landingPageWithoutSubscription = '/users-settings/my-subscription';

  readonly ResellerRoute = '/referral-program';

  readonly appNamesToUrlMapping = {
    [APP_NAMES.EXPENSES]: [
      '/expenses',
      '/accounting/summary',
      'reports/income-statement',
      'reports/wallet-transactions',
      'reports/expenses-categories',
    ],
    [APP_NAMES.ACCOUNTING]: [
      'chart-of-accounts',
      'journal-entries',
      'reports/balance-sheet',
      'reports/general-ledger',
      'reports/trial-balance',
      'reports/account-transactions',
      'finance',
      'accounting/cost-centers',
    ],
  };

  constructor(
    private permissionServiceV2: PermissionServiceV2,
    private userService: UserService,
    private authService: AuthService,
    private userAuthenticationService: UserAuthenticationService,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private readonly applicationService: ApplicationsHelperService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly permissionService: PermissionHelperServiceV2,
    private readonly userOnboardingService: UserOnboardingService,
  ) {}

  private redirectToLogin() {
    this.store.dispatch(new LogoutAction());
    this.userAuthenticationService.logout();
    return '/login';
  }

  private async fetchOnboardingData(): Promise<void> {
    const enableOnboarding =
      (await firstValueFrom(
        this.featureFlagService.isEnabled(
          FeatureFlagEnum.EnableFreeTrialUserOnboarding,
        ),
      )) &&
      (await this.permissionService.hasAtLeastOnePermission([
        'onboarding:free-trial:read',
      ])) &&
      this.isTrialUser;
    if (!enableOnboarding) return;
    await this.userOnboardingService.fetchBusinessType();
  }

  private async fetchServicesData(): Promise<void> {
    await this.applicationService.fetchApps();
    await this.userPreferencesService.checkUserPreferences();
    await this.fetchOnboardingData();
  }

  public async handleUrlPermissions(reqUrl: string): Promise<string | boolean> {
    const url = reqUrl.split('?')[0];
    this.user = await firstValueFrom(this.userService.getUser());

    const isUserAuthenticated = await firstValueFrom(
      this.authService.isAuthenticated(),
    );
    if (!isUserAuthenticated) return false;

    if (this.user && this.user.isReseller)
      return this.handleResellerRoutes(url);

    const isUserSubscriptionActive = this.isSubscriptionActive(this.user);

    const onboardingUrl = '/trial-user';

    if (url === onboardingUrl) {
      return true;
    }

    if (this.RootUrlCharacters.has(url)) {
      const onboardingRedirect = await this.redirectToOnboardingIfPending();
      if (onboardingRedirect.redirect) return onboardingRedirect.url;
    }

    await this.fetchServicesData();

    if (!isUserSubscriptionActive) {
      return this.handleInactiveUserRoutes(url);
    }

    return await this.handleActiveUserRoutes(url);
  }

  private handleResellerRoutes(navigatedUrl: string): string | boolean {
    if (navigatedUrl.includes(this.ResellerRoute)) return true;
    return this.ResellerRoute;
  }

  private isSubscriptionActive(user: AuthUser): boolean {
    if (!(user && user.RewaaAccountSubscription)) return false;
    const { RewaaAccountSubscription } = user;
    return new Date(RewaaAccountSubscription.endDate) > new Date();
  }

  private get isTrialUser(): boolean {
    return (
      this.user.RewaaAccountSubscription.status ===
      CHARGEBEE_SUBSCTIPION_STATUS.IN_TRIAL
    );
  }

  private async handleInactiveUserRoutes(
    navigatedUrl: string,
  ): Promise<string | boolean> {
    const subscriptionRoutes = new Set([
      '/users-settings/my-subscription',
      '/users-settings/my-subscription/active-plan',
      '/users-settings/my-subscription/checkout',
      '/users-settings/my-subscription/handler',
    ]);
    if (subscriptionRoutes.has(navigatedUrl)) return true;

    const subsv2 = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.SubscriptionsV2, true),
    );
    return subsv2
      ? this.landingPageWithoutSubscriptionV2
      : this.landingPageWithoutSubscription;
  }

  private async redirectToOnboardingIfPending(): Promise<{
    redirect: boolean;
    url: string;
  }> {
    const isOnboardingEnabled = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.RewaaOnboarding),
    );

    if (!isOnboardingEnabled) {
      return {
        redirect: false,
        url: '',
      };
    }

    const { onboardingStatus } = this.user;

    if (
      onboardingStatus &&
      onboardingStatus === OnboardingStatus.OnboardingPending
    ) {
      return {
        redirect: true,
        url: '/trial-user',
      };
    }

    return {
      redirect: false,
      url: '',
    };
  }

  private async handleActiveUserRoutes(url: string): Promise<string | boolean> {
    const permissions = await firstValueFrom(
      this.permissionServiceV2.getUserPermissions(),
    );
    if (!permissions.length) await this.refreshTokenAndCheck();
    const userPermissions = permissions.map((el) => el.permission);
    const routeToPermissionMap = new Map<string, string[]>();
    this.routeToPermissionMapping.forEach((route) => {
      routeToPermissionMap.set(route.route, route.permissions);
    });

    if (this.RootUrlCharacters.has(url)) {
      return this.findLandingPageForRootUrl(userPermissions);
    }

    const paidAppFix = await firstValueFrom(
      this.featureFlagService.isEnabled(FeatureFlagEnum.PaidAppFix),
    );
    if (paidAppFix && !(await this.isAppAllowed(url))) return '/applications';

    const routesWithTrailingIdentifiers = [
      '/accounting/journal-entries/edit',
      '/accounting/journal-entries/detail',
      '/accounting/journal-entries/duplicate',
      '/accounting/chart-of-accounts/detail',
      '/expenses/',
    ];
    const matchingRoute = routesWithTrailingIdentifiers.find((el) =>
      url.includes(el),
    );

    const requiredPermissions = routeToPermissionMap.get(matchingRoute || url);
    if (requiredPermissions) {
      if (this.hasAnyRequiredPermission(requiredPermissions, userPermissions)) {
        return true;
      }
      return '/unauthorised';
    }

    // checking for parent route permissions
    return this.areParentRoutesAllowed(url, userPermissions);
  }

  async refreshTokenAndCheck(): Promise<void> {
    await this.authService.forceRefreshCognitoToken();
    await this.permissionServiceV2.refreshPermissions();
    const permissions = await firstValueFrom(
      this.permissionServiceV2.getUserPermissions(),
    );
    if (!permissions.length) this.redirectToLogin();
  }

  async checkGettingToKnowRewaaPageAccess(): Promise<boolean> {
    if (!this.isTrialUser) return false;
    const isRetailer = this.userOnboardingService.isBusinessSectorRetail();
    const isStatusTrialSetup = this.userOnboardingService.isStatusTrialSetup();
    const gettingToKnowEnabled = await firstValueFrom(
      this.featureFlagService.isEnabled(
        FeatureFlagEnum.EnableFreeTrialUserOnboarding,
      ),
    );

    return isRetailer && !isStatusTrialSetup && gettingToKnowEnabled;
  }

  private checkIfWithinFirst30Days(subscriptionStartDate: Date): boolean {
    const currentDate = new Date();
    const thirtyDaysLater = addDays(subscriptionStartDate, 30);
    return isBefore(currentDate, thirtyDaysLater);
  }

  private isWithinFirst30Days(): boolean {
    const currentTermStart =
      this.user?.RewaaAccountSubscription?.currentTermStart;

    if (!currentTermStart) {
      return false;
    }
    const subscriptionStartDate = new Date(currentTermStart * 1000);
    return this.checkIfWithinFirst30Days(subscriptionStartDate);
  }

  private async checkGettingStartedAccess(): Promise<boolean> {
    const isWithInFirst30Days = this.isWithinFirst30Days();
    const isStatusTrialSetup = this.userOnboardingService.isStatusTrialSetup();
    const enabledGettingStarted = await firstValueFrom(
      this.featureFlagService.isEnabled(
        FeatureFlagEnum.EnableSubscribedUserOnboarding,
        false,
      ),
    );

    return (isWithInFirst30Days || isStatusTrialSetup) && enabledGettingStarted;
  }

  private async findLandingPageForRootUrl(
    userPermissions: string[],
  ): Promise<string> {
    const goToGettingToKnowPage =
      await this.checkGettingToKnowRewaaPageAccess();

    if (goToGettingToKnowPage) return 'getting-to-know-rewaa';

    const goToGettingStartedPage = await this.checkGettingStartedAccess();
    if (goToGettingStartedPage) {
      return '/getting-started';
    }

    for (const route of this.routeToPermissionMapping) {
      const isRouteAllowed = this.hasAnyRequiredPermission(
        route.permissions,
        userPermissions,
      );
      if (isRouteAllowed && route.route !== '') return route.route;
    }
    return '/unauthorised';
  }

  // check for parent route permissions
  private areParentRoutesAllowed(
    navigatedUrl: string,
    userPermissions: string[],
  ): string | boolean {
    const parentRoutesAllowed = [];
    for (const routeObj of this.routeToPermissionMapping) {
      const { route, permissions } = routeObj;
      if (navigatedUrl.includes(route)) {
        const isRouteAllowed = this.hasAnyRequiredPermission(
          permissions,
          userPermissions,
        );
        if (isRouteAllowed) parentRoutesAllowed.push(route);
      }
    }
    return parentRoutesAllowed.length > 0 ? true : '/unauthorised';
  }

  private hasAnyRequiredPermission(
    requiredPermissions: string[],
    userPermissions: string[],
  ) {
    if (!requiredPermissions || requiredPermissions.length === 0) return true;

    const userPermissionSet = new Set(userPermissions);
    return requiredPermissions.some((el) => userPermissionSet.has(el));
  }

  private getAppForUrl(navigatedUrl: string): APP_NAMES {
    return Object.values(APP_NAMES).find((appName) => {
      const appUrls = this.appNamesToUrlMapping[appName];
      return !!appUrls?.some((appUrl) => navigatedUrl.includes(appUrl));
    });
  }

  private async isAppAllowed(navigatedUrl: string): Promise<boolean> {
    const currentApp = this.getAppForUrl(navigatedUrl);
    return currentApp
      ? this.applicationService.isPermittedAppInstalled(currentApp)
      : true;
  }
}
