import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Merchant } from '../../shared/model/Merchant';
import { SettingsService } from '../../users-settings/services/settings.service';
import { UserSettingsUtility } from '../../users-settings/utils/user-settings.utility';
import { AuthUser } from '../auth.models';
import { MapRewaaAppsToCbFeatures } from '../../users-settings/components/my-subscription-v2/constants';

@Injectable()
export class UserService {
  private user$: BehaviorSubject<AuthUser | undefined> = new BehaviorSubject<
    AuthUser | undefined
  >(undefined);

  private users$: BehaviorSubject<Merchant[]> = new BehaviorSubject<Merchant[]>(
    [] as Merchant[],
  );

  private isUserRefreshedSubject = new BehaviorSubject<boolean>(null);

  public isUserRefreshed$: Observable<boolean> =
    this.isUserRefreshedSubject.asObservable();

  constructor(private settingsService: SettingsService) {
    if (this.isLoggedIn()) {
      this.getUsers();
    }
  }

  setUser(user: AuthUser): void {
    const clonedUser = { ...user };

    const todayDate: Date = new Date();
    const subscription = user.RewaaAccountSubscription;
    const endDate: Date = subscription
      ? new Date(subscription.endDate)
      : new Date();
    const deactivated = !!(subscription && subscription.deletedAt);
    const isActive = todayDate < endDate;

    const subscriptionDeletedAt = user.subscriptionDeletedAt
      ? new Date(user.subscriptionDeletedAt)
      : '';
    let foundUserWithoutSubscription = false;
    if (subscriptionDeletedAt) {
      const diffTime = Math.abs(
        todayDate.getTime() - subscriptionDeletedAt.getTime(),
      );
      const deletedSince = Math.ceil(diffTime / (1000 * 60 * 60));
      if (deletedSince > 24) {
        foundUserWithoutSubscription = true;
      }
    }

    clonedUser.isActive = isActive;
    if (deactivated || foundUserWithoutSubscription) {
      clonedUser.permissions = [];
    }
    if (isActive) {
      const diffTime: number = Math.abs(
        todayDate.getTime() - endDate.getTime(),
      );
      const trialDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      clonedUser.trialDays =
        subscription && subscription.trialPlan ? trialDays : undefined;
    }
    clonedUser.remainingDays =
      subscription && !subscription.trialPlan
        ? UserSettingsUtility.getExpirationInDays(subscription.endDate)
        : undefined;

    clonedUser.isExpired =
      subscription && subscription.endDate
        ? UserSettingsUtility.isExpiredSubscription(subscription.endDate)
        : false;

    clonedUser.daysLeftToDisableAccount = subscription
      ? UserSettingsUtility.getDaysLeftToDisableAccount(subscription.endDate)
      : undefined;

    this.user$.next(clonedUser);
  }

  clearUser(): void {
    this.user$.next(undefined);
  }

  getCurrentUser(): AuthUser {
    return this.user$.getValue();
  }

  getUser(): Observable<AuthUser> {
    return this.user$.asObservable();
  }

  isLoggedIn(): boolean {
    return Boolean(this.user$.getValue());
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  setUsers(users: Merchant[]): void {
    this.users$.next(users);
  }

  getUsers(): Observable<Merchant[]> {
    if (!this.users$.getValue()?.length) {
      return this.settingsService.getAllStoreUsers().pipe(
        map((users) => {
          this.setUsers(users);
          return users;
        }),
      );
    }
    return this.users$.asObservable();
  }

  hasTheUserBeenRefreshed(value: boolean): void {
    this.isUserRefreshedSubject.next(value);
  }

  shouldHrModuleBeVisible(hasHrModulePermissions: boolean): boolean {
    const { hrModuleSubscription, RewaaAccountSubscription: subscription } =
      this.user$.getValue();

    const hasActiveHrSubscription = !!(
      hrModuleSubscription &&
      hrModuleSubscription.status === 'active' &&
      hasHrModulePermissions
    );

    const allowedApps = subscription?.allowedApps || [];
    const hasHrFeatureInAllowedApps = allowedApps.some(
      (app) => app.featureId === MapRewaaAppsToCbFeatures.rewaaHR,
    );

    return (
      hasActiveHrSubscription ||
      (hasHrFeatureInAllowedApps && hasHrModulePermissions)
    );
  }
}
