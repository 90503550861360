export const MySubscriptionPath = '/users-settings/my-subscription';

export const CheckOutTypes = {
  SubscribeToAPlan: 'subscribe-plan',
  RenewPlan: 'renew-plan',
  UpgradePlan: 'upgrade-plan',
  AddLocations: 'add-locations',
  AddApplications: 'add-applications',
};

// here keys are the names of our apps
// values are the features on Chargebee
export const MapRewaaAppsToCbFeatures = {
  magento: 'magento',
  woocommerce: 'woocommerce',
  zid: 'zid',
  'new pos': 'pos',
  salla: 'salla',
  qoyod: 'qoyod',
  'promotion management': 'promotions',
  expenses: 'expenses',
  zatca: 'zatca',
  accounting: 'accounting',
  bonat: 'bonat',
  rewaaHR: 'rewaa-hr',
};

// these are statuses of tap charge
export const TapPaymentStatus = {
  Success: 'CAPTURED',
  Failed: 'FAILED',
  Cancelled: 'CANCELLED',
  Declined: 'DECLINED',
};

export const CheckOutItemTypes = {
  Plan: 'plan',
  Location: 'location',
  Application: 'application',
};

export const DiscountTypes = {
  Percentage: 'percentage',
  FixedAmount: 'fixed_amount',
};

export enum CouponApplicabilityStatus {
  NotFound = 'not_found',
  IsDuplicate = 'is_duplicate',
  NotApplicable = 'not_applicable',
  Applicable = 'applicable',
  LimitExceeded = 'redemption_limit_exceeded',
}

export const PeriodUnits = {
  Year: 'year',
};

export enum HeaderImage {
  ThumbsUp = '/assets/icons/plan-subscription-success.svg',
  PlanSuccessfulTick = '/assets/icons/plan-successful-tick.svg',
}

export const ImageAltText = {
  PaymentSuccessful: 'Payment Successful',
  Congratulations: 'Congratulations',
};

export const BillingDocumentTypes = {
  Invoice: 'invoice',
  CreditNote: 'credit_note',
};

const SmallCities = [
  { name: 'ضرما' },
  { name: 'الحريق' },
  { name: 'حوطة بني تميم' },
  { name: 'الأفلاج' },
  { name: 'الدلم' },
  { name: 'الحوطة' },
  { name: 'الدوادمي' },
  { name: 'ساجر' },
  { name: 'الجمش' },
  { name: 'الداهنة' },
  { name: 'البجادية' },
  { name: 'الرويضة' },
  { name: 'البشائر' },
  { name: 'العقيق' },
  { name: 'المخواة' },
  { name: 'بني حسن' },
  { name: 'محايل عسير' },
  { name: 'تبالة' },
  { name: 'العرضيات' },
  { name: 'العرضيتين' },
  { name: 'بيش' },
  { name: 'صامطة' },
  { name: 'العارضة' },
  { name: 'الطوال' },
  { name: 'الحرث' },
  { name: 'فرسان' },
  { name: 'أبو عريش' },
  { name: 'الداير بني مالك' },
  { name: 'العويقلية' },
  { name: 'الشعبة' },
  { name: 'حفر الباطن' },
  { name: 'الرقعي' },
  { name: 'النعيرية' },
  { name: 'الخفجي' },
  { name: 'القرية العليا' },
  { name: 'عين دار' },
  { name: 'بقيق' },
  { name: 'عريعرة' },
  { name: 'السفانية' },
  { name: 'رأس الخير' },
  { name: 'رأس تنورة' },
  { name: 'الجبيل الصناعية' },
  { name: 'الجبيل البلد' },
  { name: 'ثول' },
  { name: 'رابغ' },
  { name: 'مستورة' },
  { name: 'الكامل' },
  { name: 'خليص' },
  { name: 'عسفان' },
  { name: 'بحرة' },
  { name: 'الشعيبة' },
  { name: 'الليث' },
  { name: 'أضم' },
  { name: 'العرضية الشمالية' },
  { name: 'العرضية الجنوبية' },
  { name: 'حلي' },
  { name: 'الخالدية' },
  { name: 'الخرمة' },
  { name: 'الموية' },
  { name: 'تربة' },
  { name: 'رنية' },
  { name: 'ميسان' },
  { name: 'السحن' },
  { name: 'ثقيف' },
  { name: 'حداد بني مالك' },
  { name: 'القريع بني مالك' },
  { name: 'الجعبة' },
  { name: 'خيبر' },
  { name: 'الحناكية' },
  { name: 'العيص' },
  { name: 'بدر' },
  { name: 'وادي الفرع' },
  { name: 'مهد الذهب' },
  { name: 'الحسو' },
  { name: 'النخيل' },
  { name: 'العرجاء' },
  { name: 'السويرقية' },
  { name: 'الحمنة' },
  { name: 'الميقات' },
  { name: 'الصويدرة' },
  { name: 'أم البرك' },
  { name: 'العوشزية' },
  { name: 'الروضة' },
  { name: 'بدائع ريمان' },
  { name: 'الماوية' },
  { name: 'الدائر' },
  { name: 'القوز' },
  { name: 'قنا' },
  { name: 'وادي بن هشبل' },
  { name: 'الحريض' },
  { name: 'الشقيق' },
  { name: 'جزر فرسان' },
  { name: 'جازان' },
  { name: 'الداير' },
  { name: 'الريث' },
  { name: 'الدرب' },
  { name: 'أحد المسارحة' },
  { name: 'ضمد' },
  { name: 'صبيا' },
  { name: 'الموسم' },
  { name: 'الخوبة' },
  { name: 'الحقو' },
  { name: 'العشة' },
  { name: 'النور' },
  { name: 'الجبيلية' },
  { name: 'الجدة' },
  { name: 'حقل' },
  { name: 'حالة عمار' },
  { name: 'تيماء' },
  { name: 'ضباء' },
  { name: 'الوجه' },
  { name: 'أملج' },
  { name: 'الحيرة' },
  { name: 'الفرشة' },
  { name: 'المضة' },
  { name: 'تندحة' },
  { name: 'خيبر الجنوب' },
  { name: 'القحمة' },
  { name: 'العرين' },
  { name: 'بلقرن' },
  { name: 'خثعم' },
  { name: 'شواص' },
  { name: 'عفراء' },
  { name: 'تنومة' },
  { name: 'سعيدة الصوالحة' },
  { name: 'الحجلة' },
  { name: 'القوباء' },
  { name: 'البرك' },
  { name: 'خميس مشيط' },
  { name: 'النماص' },
  { name: 'الحرجة' },
  { name: 'ظهران الجنوب' },
  { name: 'عبس' },
  { name: 'قلوة' },
  { name: 'بلجرشي' },
  { name: 'الحجرة' },
  { name: 'غامد الزناد' },
  { name: 'القرى' },
  { name: 'المندق' },
  { name: 'ثريبان' },
  { name: 'نمرة' },
  { name: 'المظيلف' },
  { name: 'دوقة' },
  { name: 'سبت الجارة' },
  { name: 'رجال ألمع' },
  { name: 'بارق' },
  { name: 'بيشة' },
  { name: 'تثليث' },
  { name: 'الحازمي' },
  { name: 'صمخ' },
  { name: 'سبت العلايا' },
  { name: 'بللسمر' },
  { name: 'بللحمر' },
  { name: 'سراة عبيدة' },
  { name: 'أحد رفيدة' },
  { name: 'خميس البحر' },
  { name: 'الحريضة' },
  { name: 'فيفاء' },
  { name: 'العيدابي' },
  { name: 'هروب' },
  { name: 'السهي' },
  { name: 'تبوك' },
  { name: 'الحارة' },
  { name: 'الخريبة' },
  { name: 'الجهراء' },
  { name: 'المويلح' },
  { name: 'البدع' },
  { name: 'جبة' },
  { name: 'حائل' },
  { name: 'الخطة' },
  { name: 'شري' },
  { name: 'الحائط' },
  { name: 'سميراء' },
  { name: 'الشملي' },
  { name: 'السليمي' },
  { name: 'الغزالة' },
  { name: 'موقق' },
  { name: 'روض بن هادي' },
  { name: 'الحليفة' },
  { name: 'البير' },
  { name: 'الأرطاوية' },
  { name: 'ثادق' },
  { name: 'حريملاء' },
  { name: 'رماح' },
  { name: 'مرات' },
  { name: 'عفيف' },
  { name: 'نفي' },
  { name: 'عروى' },
  { name: 'البدائع' },
  { name: 'النبهانية' },
  { name: 'البكيرية' },
  { name: 'الأسياح' },
  { name: 'عيون الجواء' },
  { name: 'عقلة الصقور' },
  { name: 'الفويلق' },
  { name: 'الربيعية' },
  { name: 'عريفجان' },
  { name: 'فيد' },
  { name: 'رمان' },
];

const MediumCities = [
  { name: 'الباحة' },
  { name: 'المجاردة' },
  { name: 'القنفذة' },
  { name: 'المذنب' },
  { name: 'السليل' },
  { name: 'وادي الدواسر' },
  { name: 'عرعر' },
  { name: 'رفحاء' },
  { name: 'طريف' },
  { name: 'القريات' },
  { name: 'سكاكا' },
  { name: 'دومة الجندل' },
  { name: 'القويعية' },
  { name: 'شقراء' },
  { name: 'الزلفي' },
  { name: 'المجمعة' },
  { name: 'عنيزة' },
  { name: 'الرس' },
  { name: 'رياض الخبراء' },
  { name: 'البدائع' },
  { name: 'البكيرية' },
  { name: 'النبهانية' },
];

const MajorCities = [
  { name: 'الرياض' },
  { name: 'جدة' },
  { name: 'مكة المكرمة' },
  { name: 'المدينة المنورة' },
  { name: 'الدمام' },
  { name: 'الخبر' },
  { name: 'الطائف' },
  { name: 'تبوك' },
  { name: 'بريدة' },
  { name: 'أبها' },
  { name: 'نجران' },
  { name: 'حائل' },
  { name: 'جيزان' },
  { name: 'القطيف' },
  { name: 'ينبع' },
  { name: 'العلا' },
  { name: 'حفر الباطن' },
  { name: 'الجبيل' },
];

export const CityListsByCountry = {
  ليبيا: [
    { name: 'طرابلس' },
    { name: 'بنغازي' },
    { name: 'مصراتة' },
    { name: 'سبها' },
    { name: 'البيضاء' },
    { name: 'الزاوية' },
    { name: 'سرت' },
    { name: 'درنة' },
    { name: 'طبرق' },
    { name: 'بني وليد' },
    { name: 'زليتن' },
    { name: 'غريان' },
    { name: 'الخمس' },
    { name: 'ترهونة' },
    { name: 'مرزق' },
    { name: 'غدامس' },
    { name: 'الكفرة' },
    { name: 'المرج' },
    { name: 'رأس لانوف' },
    { name: 'البريقة' },
    { name: 'العقيلة' },
    { name: 'أوجلة' },
    { name: 'جالو' },
    { name: 'تينيري' },
  ],
  السودان: [
    { name: 'الخرطوم' },
    { name: 'أم درمان' },
    { name: 'بورتسودان' },
    { name: 'كسلا' },
    { name: 'الأبيض' },
    { name: 'عطبرة' },
    { name: 'سنار' },
    { name: 'مدني' },
    { name: 'القضارف' },
    { name: 'الفاشر' },
    { name: 'نيالا' },
    { name: 'كادوقلي' },
    { name: 'الجنينة' },
    { name: 'كوستي' },
    { name: 'ربك' },
    { name: 'رفاعة' },
    { name: 'الدمازين' },
    { name: 'شندي' },
    { name: 'سواكن' },
    { name: 'طوكر' },
    { name: 'ود مدني' },
    { name: 'دنقلا' },
    { name: 'كريمة' },
    { name: 'المتمة' },
    { name: 'الدامر' },
    { name: 'النهود' },
    { name: 'أم روابة' },
    { name: 'بارا' },
    { name: 'زالنجي' },
    { name: 'الدلنج' },
  ],
  المغرب: [
    { name: 'الرباط' },
    { name: 'الدار البيضاء' },
    { name: 'مراكش' },
    { name: 'فاس' },
    { name: 'طنجة' },
    { name: 'مكناس' },
    { name: 'أغادير' },
    { name: 'تطوان' },
    { name: 'وجدة' },
    { name: 'بني ملال' },
    { name: 'القنيطرة' },
    { name: 'آسفي' },
    { name: 'الجديدة' },
    { name: 'سلا' },
    { name: 'خريبكة' },
    { name: 'العيون' },
    { name: 'الصويرة' },
    { name: 'العرائش' },
    { name: 'تارودانت' },
    { name: 'قلعة السراغنة' },
    { name: 'تازة' },
    { name: 'الناظور' },
    { name: 'المحمدية' },
    { name: 'تمارة' },
    { name: 'ورزازات' },
    { name: 'الداخلة' },
    { name: 'بني أنصار' },
    { name: 'أولاد تايمة' },
    { name: 'تاوريرت' },
    { name: 'الخميسات' },
    { name: 'الراشيدية' },
    { name: 'بوعرفة' },
    { name: 'أزرو' },
    { name: 'دمنات' },
    { name: 'الحاجب' },
    { name: 'زايو' },
    { name: 'شفشاون' },
    { name: 'مريرت' },
    { name: 'مولاي إدريس زرهون' },
    { name: 'تارجيست' },
  ],
  الجزائر: [
    { name: 'الجزائر' },
    { name: 'وهران' },
    { name: 'قسنطينة' },
    { name: 'عنابة' },
    { name: 'تلمسان' },
    { name: 'سطيف' },
    { name: 'البليدة' },
    { name: 'باتنة' },
    { name: 'بجاية' },
    { name: 'ورقلة' },
    { name: 'غرداية' },
    { name: 'تيارت' },
    { name: 'تيزي وزو' },
    { name: 'بشار' },
    { name: 'سكيكدة' },
    { name: 'جيجل' },
    { name: 'قالمة' },
    { name: 'بسكرة' },
    { name: 'خنشلة' },
    { name: 'المدية' },
    { name: 'عين الدفلى' },
    { name: 'مستغانم' },
    { name: 'برج بوعريريج' },
    { name: 'سوق أهراس' },
    { name: 'الأغواط' },
    { name: 'بومرداس' },
    { name: 'تيسمسيلت' },
    { name: 'تبسة' },
    { name: 'سيدي بلعباس' },
    { name: 'المسيلة' },
    { name: 'الجلفة' },
    { name: 'تمنراست' },
    { name: 'إليزي' },
  ],
  لبنان: [
    { name: 'بيروت' },
    { name: 'طرابلس' },
    { name: 'صيدا' },
    { name: 'بعلبك' },
    { name: 'زحلة' },
    { name: 'جبيل' },
    { name: 'صور' },
    { name: 'النبطية' },
    { name: 'جونية' },
    { name: 'شتورة' },
    { name: 'البترون' },
    { name: 'عاليه' },
    { name: 'حمانا' },
    { name: 'راشيا' },
    { name: 'الميناء' },
    { name: 'عكار' },
    { name: 'بشرّي' },
    { name: 'بكفيا' },
    { name: 'غزير' },
    { name: 'الشوف' },
  ],
  البحرين: [
    { name: 'المنامة' },
    { name: 'المحرق' },
    { name: 'الرفاع' },
    { name: 'سترة' },
    { name: 'البديع' },
    { name: 'مدينة عيسى' },
    { name: 'مدينة حمد' },
    { name: 'عالي' },
    { name: 'الدير' },
    { name: 'السنابس' },
    { name: 'قلالي' },
    { name: 'الرفاع الشرقي' },
    { name: 'البسيتين' },
    { name: 'الجفير' },
    { name: 'الزنج' },
    { name: 'القضيبية' },
    { name: 'العدلية' },
    { name: 'السهلة' },
    { name: 'جدحفص' },
    { name: 'سار' },
    { name: 'باربار' },
    { name: 'توبلي' },
  ],
  عمان: [
    { name: 'مسقط' },
    { name: 'صلالة' },
    { name: 'نزوى' },
    { name: 'صحار' },
    { name: 'صور' },
    { name: 'البريمي' },
    { name: 'الرستاق' },
    { name: 'عبري' },
    { name: 'بهلاء' },
    { name: 'خصب' },
    { name: 'بدبد' },
    { name: 'السيب' },
    { name: 'الخابورة' },
    { name: 'شناص' },
    { name: 'قريات' },
    { name: 'صحم' },
    { name: 'بركاء' },
    { name: 'المضيبي' },
    { name: 'الحمراء' },
    { name: 'سمائل' },
    { name: 'إبراء' },
    { name: 'المزيونة' },
    { name: 'دبا' },
    { name: 'مدحاء' },
  ],
  اليمن: [
    { name: 'صنعاء' },
    { name: 'عدن' },
    { name: 'تعز' },
    { name: 'حضرموت' },
    { name: 'إب' },
    { name: 'الحديدة' },
    { name: 'المكلا' },
    { name: 'سيئون' },
    { name: 'ذمار' },
    { name: 'صعدة' },
    { name: 'عمران' },
    { name: 'لحج' },
    { name: 'المحويت' },
    { name: 'البيضاء' },
    { name: 'ريمة' },
    { name: 'الضالع' },
    { name: 'شبوة' },
    { name: 'أبين' },
    { name: 'حجة' },
  ],
  الكويت: [
    { name: 'مدينة الكويت' },
    { name: 'الجهراء' },
    { name: 'السالمية' },
    { name: 'الفحيحيل' },
    { name: 'الأحمدي' },
    { name: 'حولي' },
    { name: 'مبارك الكبير' },
    { name: 'الفروانية' },
    { name: 'صباح السالم' },
    { name: 'أبو حليفة' },
    { name: 'الصليبيخات' },
    { name: 'الرميثية' },
    { name: 'بيان' },
    { name: 'مشرف' },
    { name: 'السالمية الجديدة' },
    { name: 'الزهراء' },
    { name: 'ضاحية عبد الله السالم' },
    { name: 'العدان' },
  ],
  قطر: [
    { name: 'الدوحة' },
    { name: 'الريان' },
    { name: 'الوكرة' },
    { name: 'الخور' },
    { name: 'الشحانية' },
    { name: 'أم صلال' },
    { name: 'الزبارة' },
    { name: 'الغويرية' },
    { name: 'الدحيل' },
    { name: 'الوجبة' },
    { name: 'معيذر' },
    { name: 'الغرافة' },
    { name: 'المطار القديم' },
    { name: 'السد' },
    { name: 'بن عمران' },
    { name: 'جريان البطنة' },
    { name: 'العسيري' },
    { name: 'الجميلية' },
    { name: 'لعبيب' },
  ],
  الأردن: [
    { name: 'عمان' },
    { name: 'الزرقاء' },
    { name: 'إربد' },
    { name: 'العقبة' },
    { name: 'الكرك' },
    { name: 'المفرق' },
    { name: 'مادبا' },
    { name: 'السلط' },
    { name: 'جرش' },
    { name: 'عجلون' },
    { name: 'الطفيلة' },
    { name: 'الرمثا' },
    { name: 'معان' },
    { name: 'الفحيص' },
    { name: 'الأزرق' },
    { name: 'سحاب' },
    { name: 'الحصن' },
  ],
  'الإمارات العربية المتحدة': [
    { name: 'أبوظبي' },
    { name: 'دبي' },
    { name: 'الشارقة' },
    { name: 'العين' },
    { name: 'رأس الخيمة' },
    { name: 'عجمان' },
    { name: 'أم القيوين' },
    { name: 'الفجيرة' },
    { name: 'خورفكان' },
    { name: 'دبا الفجيرة' },
    { name: 'الذيد' },
    { name: 'مدينة زايد' },
    { name: 'الرويس' },
    { name: 'المرفأ' },
    { name: 'دلما' },
    { name: 'حتا' },
    { name: 'ليوا' },
  ],
  'المملكة العربية السعودية': [...MajorCities, ...SmallCities, ...MediumCities],
  مصر: [
    { name: 'القاهرة' },
    { name: 'الإسكندرية' },
    { name: 'الجيزة' },
    { name: 'أسوان' },
    { name: 'الأقصر' },
    { name: 'أسيوط' },
    { name: 'المنصورة' },
    { name: 'السويس' },
    { name: 'بورسعيد' },
    { name: 'المنيا' },
    { name: 'بني سويف' },
    { name: 'قنا' },
    { name: 'دمياط' },
    { name: 'سوهاج' },
    { name: 'الفيوم' },
    { name: 'الزقازيق' },
    { name: 'مرسى مطروح' },
    { name: 'الإسماعيلية' },
    { name: 'العريش' },
    { name: 'شرم الشيخ' },
    { name: 'الغردقة' },
    { name: 'طنطا' },
    { name: 'شبين الكوم' },
    { name: 'دمنهور' },
    { name: 'كفر الشيخ' },
    { name: 'بنها' },
    { name: 'ملوي' },
    { name: 'السادات' },
    { name: 'إدفو' },
    { name: 'ديروط' },
    { name: 'المنزلة' },
    { name: 'نصر النوبة' },
    { name: 'أبو سمبل' },
  ],
  العراق: [
    { name: 'بغداد' },
    { name: 'البصرة' },
    { name: 'الموصل' },
    { name: 'أربيل' },
    { name: 'كربلاء' },
    { name: 'النجف' },
    { name: 'السليمانية' },
    { name: 'دهوك' },
    { name: 'الناصرية' },
    { name: 'العمارة' },
    { name: 'الرمادي' },
    { name: 'الديوانية' },
    { name: 'الكوت' },
    { name: 'السماوة' },
    { name: 'الحلة' },
    { name: 'الفلوجة' },
    { name: 'سامراء' },
    { name: 'تكريت' },
    { name: 'كركوك' },
    { name: 'الحويجة' },
    { name: 'المقدادية' },
    { name: 'البعاج' },
    { name: 'بيجي' },
    { name: 'تلعفر' },
    { name: 'القائم' },
  ],
};

export const CountriesList = [
  { name: 'المملكة العربية السعودية' },
  { name: 'مصر' },
  { name: 'العراق' },
  { name: 'الإمارات العربية المتحدة' },
  { name: 'الأردن' },
  { name: 'قطر' },
  { name: 'الكويت' },
  { name: 'اليمن' },
  { name: 'عمان' },
  { name: 'البحرين' },
  { name: 'لبنان' },
  { name: 'الجزائر' },
  { name: 'المغرب' },
  { name: 'السودان' },
  { name: 'ليبيا' },
];

export const CountryCodes = {
  'المملكة العربية السعودية': 'SA',
  مصر: 'EG',
  العراق: 'IQ',
  'الإمارات العربية المتحدة': 'AE',
  الأردن: 'JO',
  قطر: 'QA',
  الكويت: 'KW',
  اليمن: 'YE',
  عمان: 'OM',
  البحرين: 'BH',
  لبنان: 'LB',
  الجزائر: 'DZ',
  المغرب: 'MA',
  السودان: 'SD',
  ليبيا: 'LY',
};

export const CountryCodesToCountries = {
  SA: 'المملكة العربية السعودية',
  EG: 'مصر',
  IQ: 'العراق',
  AE: 'الإمارات العربية المتحدة',
  JO: 'الأردن',
  QA: 'قطر',
  KW: 'الكويت',
  YE: 'اليمن',
  OM: 'عمان',
  BH: 'البحرين',
  LB: 'لبنان',
  DZ: 'الجزائر',
  MA: 'المغرب',
  SD: 'السودان',
  LY: 'ليبيا',
};
